import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn, DetailRow } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager, Query } from "@syncfusion/ej2-data";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
import SearchToolbar from "@/components/Templates/Service/SearchToolbar.vue";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    components: {
        "search-toolbar": SearchToolbar
    },
    mixins: [GridMixin, TitleMixin],
    data() {
        return {
            title: this.$t("services.services"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "services/grid-data",
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "services/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "services/grid-edit",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "services/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [{ field: "Name", direction: "Ascending" }]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            searchOptions: {
                fields: ["Name", "Description"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ],
            childGrid: this.$store.getters["account/hasMultipleOffices"] ? {
                queryString: 'ServiceId',
                columns: [
                    { field: 'ServiceId', headerText: this.$t('shared.id'), width: 0, visible: false },
                    { field: 'OfficeId', headerText: this.$t('shared.id'), width: 0, visible: false },
                    { field: 'OfficeName', headerText: this.$t('offices.office'), width: 300 },
                    { field: 'Price', headerText: this.$t('shared.price'), width: 100, format: "C2" }
                ],
                load: function () {
                    const grid = this;
                    grid.parentDetails.parentKeyFieldValue = grid.parentDetails.parentRowData['Id'];
                }
            } : undefined
        };
    },
    provide: {
        grid: [Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn, DetailRow]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        }),
    },
    methods: {
        onBeforeDataBound(args) {
            if (!this.hasMultipleOffices) {
                const userOfficeId = this.getSingleOfficeId;
                for (const service of args.result) {
                    const serviceOffice = service.Offices?.find(serviceOffice => serviceOffice.OfficeId === userOfficeId);
                    service.Price = serviceOffice?.Price;
                }
            }
        },
        search(args) {
            const grid = this.$refs["services-grid"];
            const gridInstance = grid.ej2Instances;
            // Build query
            const query = new Query();
            if (args.officeId != null)
                query.addParams("officeId", args.officeId.toString());
            // Set the query and refresh the grid
            gridInstance.query = query;
            grid.refresh();
        },
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/administration/services/create");
                return;
            }
        },
        commandClick(args) {
            if (args.commandColumn.type === "Custom_Edit") {
                this.$router.push(`/administration/services/${args.rowData.Id}/edit`);
                return;
            }
        },
        onDetailDataBound(args) {
            const grid = args.detailElement.querySelector('.e-grid');
            const gridInstance = grid.ej2_instances[0];
            gridInstance.dataSource = args.data.Offices;
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("services.delete.toastSuccessTitle"), this.$t("services.delete.toastSuccessContent", {
                    name: args.data[0].Name
                }));
            }
        }
    }
});
